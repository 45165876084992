<template>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12 d-flex">
        <div class="wrapper w-100 m-auto pb-5 teste">
          <div class="px-4 mb-5">
            <p class="titulo-pag">Parceria</p>
            <h1 class="mt-3">Editar parceria</h1>
          </div>
          <FormParceria v-if="loaded" :registry="registry" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FormParceria from './FormParceria.vue';
import { ParceriasRepository } from '../../libs/repositories';

export default {
  name: 'ParceriasEdicao',
  data () {
    return {
      loaded: false,
      registry: null
    };
  },
  components: {
    FormParceria
  },
  async created () {
    const id = +this.$route.params.id;

    const registry = await ParceriasRepository.getById(id);

    if (registry) {
      this.registry = registry;
      this.loaded = true;
    }
  }
};

</script>
<style scoped>
  .titulo-pag{
    font-size: 14px;
    font-weight:600;
    color: #0A85CA;
  }
  h1{
    font-size: 22px;
    font-weight: bolder;
  }
  h1+span{
    font-size: 14px;
    color: #717171;
  }
</style>
